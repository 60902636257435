import { useEffect, useState } from "react";
import "./App.css";
import jQuery, { error } from "jquery";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
function App() {
  const [variable, setVariable] = useState("");
  const [phone, setPhone] = useState();
  const [text, setText] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [btn, setBtn] = useState(false);
  const [callBackBtn, setCallBackBtn] = useState(false);
  const [balance, setBalance] = useState("");
  const [ip , setIp] = useState("")

  useEffect(() => {

    jQuery.getJSON("https://api.ipify.org?format=json", function(data) {
         
    // Setting text of element P with id gfg
   setIp(data.ip);
})

    axios.get("https://bsnlsms.upgraderz.com/balance.php").then((response) => {
      setBalance(response.data.Error);
    });

    axios
      .get("https://bsnlsms.upgraderz.com/Templates.php")
      .then((response) => {
        let result = response.data.Content_Template_Ids;
        setTemplates(...templates, result);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setText(true);
    jQuery.ajax({
      type: "POST",
      url: "https://bsnlsms.upgraderz.com/message.php",
      data: {
        phone: phone,
        variable: variable,
      },
      success: function (result) {
        setText(false);
        alert(result);
      },
    });
  };

  function onChange(e) {
    if (e.length > 100) {
      setBtn(true);
    }
  }
  function callback(e) {
    if (e.length > 100) {
      setCallBackBtn(true);
    }
  }

  const data = {
    name: "",
    phone: "",
  };
  const [input, setInput] = useState(data);
  const[callbackText , setCallbackText] = useState(false);
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };
  const reqCallBack = (e) => {
    e.preventDefault();
    setCallbackText(true);
    var params = new URLSearchParams();
    params.append("name", input.name);
    params.append("phone", input.phone);
    axios
      .post("https://bsnlsms.upgraderz.com/callbackmessage.php", params)
      .then((response) => {
        if(response.data == "ok"){
          alert("Success...");
          setCallbackText(false);
          window.location.reload();
        }
        else{
          alert("Something Went Wrong..")
        }
      });
  };
  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
      <h1 className=" text-center py-5 text-decoration-underline">
        BSNL BULK SMS
      </h1>
      <div className="row w-100">
        <div className="col-lg-4 col-md-4 col-12 d-flex flex-column  align-items-center">
          <h3>Balance Details</h3>
          <div className="w-100  d-flex flex-column align-items-start justify-content-center py-5">
            <h5>Blanace SMS : {balance}</h5>
            <h5>Validity :</h5>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-12 d-flex flex-column align-items-center">
          <h3 className="pb-3 text-decoration-underline ">
            Sent Sample Message
          </h3>
          <form onSubmit={handleSubmit} className="p-5">
            <label htmlFor="phone" className="form-label">
              Enter Phone Number
            </label>
            <input
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              type="tel"
              className="form-control"
              required
            />
            <label htmlFor="variable" className="form-label">
              Enter a Variable
            </label>
            <input
              value={variable}
              onChange={(e) => {
                setVariable(e.target.value);
              }}
              type="text"
              className="form-control"
              required
            />
            <ReCAPTCHA
              className="pt-3 pb-2"
              sitekey="6LcoNcUlAAAAABB7-RGD2gFtQ7REkR81bG_LmCTp"
              onChange={onChange}
              style={{width:"10px"}}
            />
            <button
              disabled={btn ? false : true}
              type="submit"
              className="btn btn-info w-100 my-3"
            >
              {text ? "Please Wait.." : "Send Message"}
            </button>
          </form>
        </div>
        <div className="col-lg-4 col-md-4 col-12 d-flex flex-column  align-items-center">
          <h3 className="pb-3 text-decoration-underline">All Templates</h3>
          <ol>
            {templates.map((e, index) => {
              return <li key={index}>{e.Template_Message}</li>;
            })}
          </ol>
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-md-4 col-lg-4 col-12">
            <h3 className="my-3 text-center">Request Callback</h3>
            <form onSubmit={reqCallBack} className="p-3">
              <label className="form-label" htmlFor="">
                Full Name
              </label>
              <input
                onChange={handleChange}
                required
                className="form-control mb-3"
                type="text"
                name="name"
                placeholder="Enter Your Full Name"
              />
              <label className="form-label" htmlFor="">
                Phone
              </label>
              <input
                onChange={handleChange}
                required
                type="tel"
                className="form-control"
                name="phone"
                placeholder="Enter your Phone Number"
              />
              <ReCAPTCHA
                className="pt-3 pb-2 w-100"
                sitekey="6LcoNcUlAAAAABB7-RGD2gFtQ7REkR81bG_LmCTp"
                onChange={callback}
              />
              <button
                disabled={callBackBtn ? false : true}
                type="submit"
                className="btn btn-info w-100"
              >
                {callbackText?"Please Wait..." : "Submit"}
              </button>
            </form>
          </div>
          <div className="col-md-8 col-lg-8 col-12 d-flex flex-column align-items-center justify-content-center">
            <p className="fs-5" >To Know More about Bulk SMS API Integration </p>
            <p className="fs-5">Mail To : <a href="mailto:upgraderzindia@gmail.com" className="text-info fw-bold">upgraderzindia@gmail.com</a></p>
          </div>
        </div>
      </div>
      <div className="container w-100">
            <p className="text-center">Site Designed and Managed By <a target="_blank" className="text-info fw-bold" href="https://vaisakhpushpan.github.io/portfolio/">Vaisakh Pushpan</a></p>
            <p className="text-center">© <a target="_blank" className="text-info fw-bold" href="https://upgraderz.com/">Upgraderz Digital</a></p>
            <p className="text-center">{ip}</p>
      </div>
    </div>
  );
}

export default App;
